
import jQuery from './jquery.min';
import { CountUp } from './countup';

require('./circle-progress');

jQuery(document).ready(function($)
{
    $(document).on('turbolinks:load', function(e){
        renderPersonalStat($('.thermometer-chart.current .personal-thermometer'), $('.thermometer-chart.current .personal-chart'));
    });
    renderPersonalStat($('.thermometer-chart.current .personal-thermometer'), $('.thermometer-chart.current .personal-chart'));
});

function renderPersonalStat(thermometerElem, personalChart, daysRemaining = true, chartId = '') {
    if ( thermometerElem.length ) {
        const goal = thermometerElem.data('goal');
        const donation = thermometerElem.data('donation');

        thermometerElem.html([
            getThermometerHTML({
                type: 'main',
                goal: goal / 100.0,
                raised: donation / 100.0,
                showNumbersAbove: true,
                showPercentageInside: true,
                delay: 400,
                transitionTime: 3000,
                daysRemaining,
            })
        ]);

        setTimeout(()=>{
            updateThermWidth(thermometerElem);
        }, 200);

        $(window).resize(function() {
            updateThermWidth(thermometerElem);
        });
    }

    if (personalChart.length) {
        const goal = personalChart.data('service-goal');
        const days = personalChart.data('served-days');
        getCircleStat(goal, days, chartId);
    }
}

function updateThermWidth(parentElement) {
    const parentWidth = parentElement.find('.thermouter .thermbar').innerWidth();
    parentElement.find('.thermouter .thermbar .textInBar.days-remaining.front').css({'width': `${parentWidth}px`});
}

function formatMoney(n, c, d, t)
{
    var c = isNaN(c = Math.abs(c)) ? 2 : c,
        d = d == undefined ? "." : d,
        t = t == undefined ? "," : t,
        s = n < 0 ? "-" : "",
        i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
        j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
}

function getCircleStat(goal, days, chartId) {
    const daysServed = days/goal;
    const percentageDaysServed = Math.trunc(daysServed * 100);

    jQuery(`#circle-percentage${chartId}`).circleProgress({
        value: daysServed.toFixed(2),
        size: 100,
        fill: "#29ABE2",
        animation: {
            duration: 2500,
        },
    });
    if ( percentageDaysServed >= 1 ) {
        const countUp = new CountUp(`days-percentage${chartId}`, percentageDaysServed, {
            duration: 3,
        });
        if (!countUp.error) {
            countUp.start();
        } else {
            console.error(countUp.error);
        }
    }
}

function getThermometerHTML(o)
{
    let numbersAbove = '',
        headerAbove = '',
        numberInside = '',
        thermBars = '',
        $therm,
        goal = o.goal;

    const animDelay = o.delay || 150;
    const totalTransitionTime = o.transitionTime || 1800;


    if (o.fillBar && !goal)
    {
        goal = 100;
    }

    const raised = o.fillBar ? (goal || 100) : (o.raised || 0);

    if (o.headerAbove) {
        headerAbove = '<div class="textAboveBar header"></div>';
    }
    if (o.showNumbersAbove) {
        numbersAbove =
            '<div class="totalgoalAmount textAboveBar"></div>' +
            '<div class="raisedAmount raisedAmount0 textAboveBar"></div>';
    }

    const goals = o.goals ? o.goals.slice() : [ goal || 100 ];

    $.each(goals, function(i) {
        var _type = (o.types && o.types[i]) || o.type || '';
        if (_type) { _type = ' ' + _type; }

        const leftPercentage = 100 * i / goals.length;
        const maxWidthPercentage = 1 / goals.length;
        const extraAttrs = ' style="left:' + leftPercentage + '%;" data-max-width-perc="' + maxWidthPercentage + '";';

        //if (goals.length === 1) style = '';
        // not sure why the previous line was here. So, it is commented out 20190718
        if (o.daysRemaining) {
            const toDay = new Date();
            const endOfYear=new Date(toDay.getFullYear(), 11, 31);
            const oneDay=1000*60*60*24;
            const daysRemaining = Math.ceil((endOfYear.getTime()-toDay.getTime())/(oneDay));
            thermBars += `<div class="textInBar days-remaining back">${daysRemaining} days remaining</div>`;
            thermBars += '<div class="raisedBar raisedBar' + i + _type + '"' + extraAttrs + '>' +
                `<div class="textInBar days-remaining front">${daysRemaining} days remaining</div>`+
                '</div>';
        } else {
            thermBars += '<div class="raisedBar raisedBar' + i + _type + '"' + extraAttrs + '></div>';
        }

        if (o.showPercentageInside) {
            thermBars += '<div class="textInBar raisedPercentage raisedPercentage' + i + _type + '"' + extraAttrs + '></div>';
        }
        else if (o.showAmountInside) {
            thermBars += '<div class="textInBar raisedAmount raisedAmount' + i + _type + '"' + extraAttrs + '></div>';
        }


    });

    $therm = $(
        '<div class="thermouter">' +
        headerAbove +
        numbersAbove +
        '<div class="thermbar">' +
        thermBars +
        '</div>' +
        '</div>'
    );


    $('.totalgoalAmount', $therm).text('$' + formatMoney(goal,0) + ' goal');
    $('.header', $therm).text(o.headerAbove);

    if (o.type)
    {
        // will apply class to the thermouter.
        $therm.addClass(o.type);
    }
    const hasTextAbove = !!(headerAbove || numbersAbove);
    if (hasTextAbove)
    {
        $therm.addClass('hasTextAbove');
    }

    var currentTimeout = null;
    startAnim();

    function startAnim(delay) {
        if (currentTimeout)
        {
            clearTimeout(currentTimeout);
        }

        for (var i = goals.length - 1; i >= 0; i--)
        {
            // this will reset them all.... just in case....
            doAnim(i, delay || animDelay);
        }
    }
    function doAnim(num, delay) {
        if (currentTimeout)
        {
            clearTimeout(currentTimeout);
        }

        var subDiv = 50, // millis
            startTimestamp;

        var $raisedBar = $('.raisedBar' + num, $therm);
        var $aboveBarRaisedAmount = $('.textAboveBar.raisedAmount', $therm);
        var $aboveBarGoalAmount = $('.textAboveBar.totalgoalAmount', $therm);

        var $raisedAmount = $('.raisedAmount' + num, $therm);
        var $raisedPercentage = $('.raisedPercentage' + num, $therm);

        const capForBar = goals[num];
        const maxAmount = o.fillBar ? goals[num] : raised;

        var transitionTime = totalTransitionTime / goals.length;
        var isMulti = goals.length > 1;

        updateText(0, true); // show initial values.
        $raisedBar.css({'width': 0, 'transition-duration': '1ms'});
        $aboveBarRaisedAmount.css({'opacity':'1', 'right': '100%', 'transition-duration': '1ms,1ms'});
        $aboveBarGoalAmount.css({'opacity': '1', 'transition-duration': '1ms'});

        if (maxAmount == 0)
        {
            // SPECIAL CASE.
            $aboveBarRaisedAmount.hide();  // this looks better.
        }

        currentTimeout = setTimeout(function() {
            const maxWidth = Number($raisedBar.data('max-width-perc'));
            const howFarPercentage = (100 * Math.min(1,maxAmount / goals[num])) * maxWidth;  // don't exceed the bar.
            const barTransitionTime = transitionTime * Math.min(1, capForBar / maxAmount);   // but speed up the bar if need-be.

            $raisedBar.css({'width': String(howFarPercentage) + '%', 'transition-duration': String(barTransitionTime) + 'ms', 'transition-timing-function': (isMulti && 'linear')});
            $aboveBarRaisedAmount.css({'right': String(100 - howFarPercentage) + '%', 'transition-duration': String(barTransitionTime) + 'ms' + ',' + String(barTransitionTime / 3) + 'ms',  'transition-timing-function': (isMulti && 'linear')});

            if (maxAmount >= goals[num] && $aboveBarRaisedAmount.length)
            {
                // SPECIAL CASE: fully funded!!; hide "goal" above in deference to "raised" above.
                $aboveBarGoalAmount.css({'opacity': '0', 'transition-delay': String(barTransitionTime / 2) + 'ms', 'transition-duration': String(barTransitionTime / 2) + 'ms'});
            }
            else if (maxAmount > (goals[num] * 0.75) && $aboveBarRaisedAmount.length)
            {
                // SPECIAL CASE: not quite fully funded...; hide "raised" above in deference to "goal" above.
                $aboveBarRaisedAmount.css({'opacity': '0', 'transition-delay': '0,' + String(barTransitionTime * 2 / 3) + 'ms'});
            }

            startTimestamp = new Date().getTime();
            nextStep();

        }, delay || 1 /* ms */);

        function nextStep() {
            // interval to update the text!
            var now = new Date().getTime(),
                t = (now - startTimestamp) / transitionTime;

            if (t >= 1)
            {
                updateText(1);
                if (num < goals.length - 1)
                {
                    // FINAL ACTION.
                    doAnim(num + 1);
                }
            }
            else
            {
                updateText(t);
                currentTimeout = setTimeout(nextStep, subDiv);
            }
        }

        function updateText(t, showingInitial) {
            // update the text!
            var s = 0;
            if (t > 0) {
                if (isMulti)
                {
                    s = t;
                }
                else
                {
                    s = (--t)*t*t+1;  // easeOutCubic - thanks https://gist.github.com/gre/1650294
                }
            }

            var amount = maxAmount * s;

            if (showingInitial && num !== 0)
            {
                $raisedAmount.text('');
                $raisedPercentage.text('');
            }
            else
            {
                $raisedAmount.text('$' + formatMoney(amount,0) + (o.fillBar ? '' : ' raised'));
                $raisedPercentage.text(String(Math.round(100 * amount / goals[num])) + '%');
            }
        }
    }

    $therm.on('restartAnim', function() { startAnim(); } );
    return $therm;
}

export {
    getThermometerHTML,
    getCircleStat,
    renderPersonalStat,
}